<template>
  <v-container id="dashboard" fluid tag="section">
    <v-flex xs12 sm12 md10 offset-md-1>
      <material-card>
        <template v-slot:heading>
          <div class="display-2 font-weight-light">Close Outbound</div>
        </template>
        <v-row row>
          <v-col md="2" xs="12"> Search/Busqueda </v-col>
          <v-col md="2" xs="12"> Company/Compania </v-col>
          <v-col md="2" xs="12"> Checkin Date/Fecha Checkin</v-col>
        </v-row>
        <v-row row>
          <v-col md="2" xs="12">
            <v-text-field
              solo
              class="right-input"
              v-model="form.search"
              maxlength="13"
              placeholder="Search order:"
              append-icon="fas fa-search"
            ></v-text-field>
          </v-col>
          <v-col md="2" xs="12">
            <v-autocomplete
              solo
              :items="controls.companies"
              item-text="companyName"
              item-value="idCompany"
              v-model="form.idCompany"
            ></v-autocomplete>
          </v-col>
          <v-col md="2" xs="12">
            <date-range-picker
              solo
              ref="picker"
              :opens="controls.datepicker.opens"
              :minDate="controls.datepicker.minDate"
              :maxDate="controls.datepicker.maxDate"
              :singleDatePicker="controls.datepicker.singleDatePicker"
              :timePicker="controls.datepicker.timePicker"
              :showWeekNumbers="controls.datepicker.showWeekNumbers"
              :showDropdowns="controls.datepicker.showDropdowns"
              :autoApply="controls.datepicker.autoApply"
              v-model="controls.datepicker.dateRange"
              :ranges="controls.datepicker.show_ranges ? undefined : false"
              @update="updateValues"
              @toggle="checkOpen"
              :linkedCalendars="controls.datepicker.linkedCalendars"
              :dateFormat="dateFormat"
            >
              <div slot="input" slot-scope="picker" style="min-width: 150px">
                <div
                  class="
                    v-input
                    theme--light
                    v-text-field
                    v-text-field--single-line
                    v-text-field--solo
                    v-text-field--is-booted
                    v-text-field--enclosed
                    v-select v-autocomplete
                  "
                >
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        {{ picker.startDate | date }} -
                        {{ picker.endDate | date }}
                      </div>
                      <div class="v-input__append-inner">
                        <div class="v-input__icon v-input__icon--append">
                          <v-icon right>mdi-calendar-range</v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <template slot="calendar-icon">
                <icon>mdi-calendar</icon>
              </template>
            </date-range-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" xs="12">
            <v-btn color="green" dark @click="fetchData" :loading="isLoading">
              Find/Buscar
              <v-icon right>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="blue"
              dark
              @click="download"
              :loading="isLoading"
              style="margin-left: 5px !important"
            >
              Export/Exportar
              <v-icon right>mdi-download</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-layout row wrap>
          <v-flex md12 sm12>
            <material-card icon="mdi-location-enter" title="Close Outbound">
              <v-data-table
                :headers="outbound.close.headers"
                :items="outbound.close.data"
                :options.sync="outbound.close.options"
                :server-items-length="outbound.close.totalItems"
                :loading="outbound.close.isLoading"
                class="elevation-1"
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.grower }}</td>
                  <td>{{ props.item.po }}</td>
                  <td>{{ props.item.companyName }}</td>
                  <td>{{ props.item.checkInDate }}</td>
                  <td>{{ props.item.doorName }}</td>
                  <td>{{ props.item.statusDesc }}</td>
                  <td>
                    <div
                      class="color"
                      :class="props.item.efficiencyColor"
                    ></div>
                  </td>
                </template>
                <template
                  v-slot:[`item.actions`]="{ item }"
                  class="justify-center layout px-0"
                >
                  <v-icon
                    small
                    class="mr-2"
                    @click="goInbound(item)"
                    title="Edit/Editar"
                    >mdi-more</v-icon
                  >
                </template>
                <template v-slot:[`item.closeAt`]="{ item }">
                  {{ $moment(item.closeAt).format("YYYY/MM/DD") }}
                </template>
                <template v-slot:[`item.airBags`]="{ item }">
                  <div class="d-flex justify-end">${{ item.airBags }}</div>
                </template>
                <template v-slot:[`item.pollyBags`]="{ item }">
                  <div class="d-flex justify-end">${{ item.pollyBags }}</div>
                </template>
                <template v-slot:[`item.temperatureRecorder`]="{ item }">
                  <div class="d-flex justify-end">
                    ${{ item.temperatureRecorder }}
                  </div>
                </template>
                <template v-slot:[`item.outGPSRecorder`]="{ item }">
                  <div class="d-flex justify-end">
                    ${{ item.outGPSRecorder }}
                  </div>
                </template>
                <template v-slot:[`item.outPalletMovement`]="{ item }">
                  <div class="d-flex justify-end">
                    ${{ item.outPalletMovement }}
                  </div>
                </template>
                <template v-slot:[`item.outSeal`]="{ item }">
                  <div class="d-flex justify-end">${{ item.outSeal }}</div>
                </template>
                <template v-slot:[`item.outOvertime`]="{ item }">
                  <div class="d-flex justify-end">${{ item.outOvertime }}</div>
                </template>
                <template v-slot:[`item.outPTI`]="{ item }">
                  <div class="d-flex justify-end">${{ item.outPTI }}</div>
                </template>
                <template slot="pageText" slot-scope="props"
                  >Lines {{ props.pageStart }} - {{ props.pageStop }} de
                  {{ props.itemsLength }}</template
                >
                <template slot="body.append">
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ outbound.close.totals.airBagsQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ outbound.close.totals.airBags }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ outbound.close.totals.pollyBagsQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ outbound.close.totals.pollyBags }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ outbound.close.totals.temperatureRecorderQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ outbound.close.totals.temperatureRecorder }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ outbound.close.totals.outGPSRecorderQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ outbound.close.totals.outGPSRecorder }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ outbound.close.totals.outPalletMovementQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ outbound.close.totals.outPalletMovement }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ outbound.close.totals.outSealQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ outbound.close.totals.outSeal }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ outbound.close.totals.outOvertimeQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ outbound.close.totals.outOvertime }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ outbound.close.totals.outPTIQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ outbound.close.totals.outPTI }}
                      </div>
                    </th>
                  </tr>
                </template>
              </v-data-table>
            </material-card>
          </v-flex>
        </v-layout>
      </material-card>
    </v-flex>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import companies from "@/services/api/companies";
import outbound from "@/services/api/outbound";

export default {
  name: "CloseReport",
  filters: {
    date(value) {
      if (!value) return "";
      return moment(value).format("YYYY-MM-DD");
    },
  },
  data() {
    return {
      form: {
        search: null,
        idCompany: null,
        date: {},
      },
      controls: {
        companies: [],
        searchForm: null,
        datepicker: {
          opens: "center",
          minDate: "2021-06-01",
          maxDate: moment().add(1, "days").format("YYYY-MM-DD"),
          dateRange: {
            startDate: moment().startOf("month").format("YYYY-MM-DD"),
            endDate: moment().add(1, "days").format("YYYY-MM-DD"),
          },
          show_ranges: true,
          singleDatePicker: false,
          timePicker: false,
          timePicker24Hour: false,
          showDropdowns: true,
          autoApply: false,
          showWeekNumbers: true,
          linkedCalendars: true,
        },
      },
      outbound: {
        close: {
          headers: [
            {
              text: "Customer",
              align: "left",
              sortable: true,
              value: "customer",
            },
            {
              text: "PU",
              align: "left",
              sortable: true,
              value: "so",
            },
            {
              text: "Close",
              align: "left",
              sortable: true,
              value: "closeAt",
            },
            {
              text: "Company",
              align: "left",
              sortable: true,
              value: "companyName",
            },
            {
              text: "Air Bags Qty",
              align: "right",
              sortable: false,
              value: "airBagsQty",
            },
            {
              text: "Air Bags",
              align: "right",
              sortable: false,
              value: "airBags",
            },
            {
              text: "Poly Bags Qty",
              align: "right",
              sortable: false,
              value: "pollyBagsQty",
            },
            {
              text: "Poly Bags",
              align: "right",
              sortable: false,
              value: "pollyBags",
            },
            {
              text: "Temp Recorder Qty",
              align: "right",
              sortable: false,
              value: "temperatureRecorderQty",
            },
            {
              text: "Temp Recorder",
              align: "right",
              sortable: false,
              value: "temperatureRecorder",
            },
            {
              text: "GPS Recorder Qty",
              align: "right",
              sortable: false,
              value: "outGPSRecorderQty",
            },
            {
              text: "GPS Recorder",
              align: "right",
              sortable: false,
              value: "outGPSRecorder",
            },
            {
              text: "Pallet Movement Qty",
              align: "right",
              sortable: false,
              value: "outPalletMovementQty",
            },
            {
              text: "Pallet Movement",
              align: "right",
              sortable: false,
              value: "outPalletMovement",
            },
            {
              text: "Seal Qty",
              align: "right",
              sortable: false,
              value: "outSealQty",
            },
            {
              text: "Seal",
              align: "right",
              sortable: false,
              value: "outSeal",
            },
            {
              text: "Overtime Qty",
              align: "right",
              sortable: false,
              value: "outOvertimeQty",
            },
            {
              text: "Overtime",
              align: "right",
              sortable: false,
              value: "outOvertime",
            },
            {
              text: "PTI Qty",
              align: "right",
              sortable: false,
              value: "outPTIQty",
            },
            {
              text: "PTI",
              align: "right",
              sortable: false,
              value: "outPTI",
            },
          ],
          data: [],
          pagination: {
            filters: "",
            bActivePaged: true,
            page: 1,
            itemsPerPage: 15,
            sortBy: "",
            sortDesc: false,
          },
          totals: {
            airBags: 0,
            pollyBags: 0,
            temperatureRecorder: 0,
            outGPSRecorder: 0,
            outPalletMovement: 0,
            outSeal: 0,
            outOvertime: 0,
            outPTI: 0,
            airBagsQty: 0,
            pollyBagsQty: 0,
            temperatureRecorderQty: 0,
            outGPSRecorderQty: 0,
            outPalletMovementQty: 0,
            outSealQty: 0,
            outOvertimeQty: 0,
            outPTIQty: 0,
          },
          options: {},
          totalItems: 0,
          isLoading: false,
        },
      },
    };
  },
  components: {
    MaterialCard: () => import("@/components/base/MaterialCard"),
  },
  created() {
    this.getAllCompanies("");
    this.updateValues(this.controls.datepicker.dateRange);
  },
  beforeDestroy() {},
  watch: {
    "outbound.close.options": {
      handler() {
        this.getOutboundClose(false);
      },
      deep: true,
    },
    search: {
      handler() {
        this.getOutboundClose(false);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    async fetchData() {
      this.getOutboundClose();
    },

    async getOutboundClose() {
      this.outbound.close.isLoading = true;
      try {
        this.outbound.close.pagination.filters = this.form.search;
        this.outbound.close.pagination.idCompany = this.form.idCompany;
        this.outbound.close.pagination.startDate = this.form.date.startDate;
        this.outbound.close.pagination.endDate = this.form.date.endDate;
        this.outbound.close.pagination.bActivePaged = true;
        this.outbound.close.pagination.page = this.outbound.close.options.page;
        this.outbound.close.pagination.itemsPerPage =
          this.outbound.close.options.itemsPerPage;
        if (this.outbound.close.options.sortBy) {
          this.outbound.close.pagination.sortBy =
            this.outbound.close.options.sortBy[0];
        }
        if (this.outbound.close.options.sortDesc) {
          this.outbound.close.pagination.sortDesc =
            this.outbound.close.options.sortDesc[0];
        }

        this.controls.searchForm = this.outbound.close.pagination;
        let OData = Object.assign({}, this.outbound.close.pagination);
        OData.qaFormat = JSON.stringify(
          this.outbound.close.pagination.qaFormat
        );

        const response = await outbound.calculationClosePaged(OData);
        this.outbound.close.data = response.body.data;
        this.outbound.close.totalItems = response.body.totalItems;

        this.outbound.close.totals.airBags = 0;
        this.outbound.close.totals.pollyBags = 0;
        this.outbound.close.totals.temperatureRecorder = 0;
        this.outbound.close.totals.outGPSRecorder = 0;
        this.outbound.close.totals.outPalletMovement = 0;
        this.outbound.close.totals.outSeal = 0;
        this.outbound.close.totals.outOvertime = 0;
        this.outbound.close.totals.outPTI = 0;

        this.outbound.close.totals.airBagsQty = 0;
        this.outbound.close.totals.pollyBagsQty = 0;
        this.outbound.close.totals.temperatureRecorderQty = 0;
        this.outbound.close.totals.outGPSRecorderQty = 0;
        this.outbound.close.totals.outPalletMovementQty = 0;
        this.outbound.close.totals.outSealQty = 0;
        this.outbound.close.totals.outOvertimeQty = 0;
        this.outbound.close.totals.outPTIQty = 0;

        this.outbound.close.data.forEach((i) => {
          this.outbound.close.totals.airBags += i.airBags;
          this.outbound.close.totals.pollyBags += i.pollyBags;
          this.outbound.close.totals.temperatureRecorder +=
            i.temperatureRecorder;
          this.outbound.close.totals.outGPSRecorder += i.outGPSRecorderQty;
          this.outbound.close.totals.outPalletMovement +=
            i.outPalletMovementQty;
          this.outbound.close.totals.outSeal += i.outSealQty;
          this.outbound.close.totals.outOvertime += i.outOvertime;
          this.outbound.close.totals.outPTI += i.outPTI;

          this.outbound.close.totals.airBagsQty += i.airBagsQty;
          this.outbound.close.totals.pollyBagsQty += i.pollyBagsQty;
          this.outbound.close.totals.temperatureRecorderQty +=
            i.temperatureRecorderQty;
          this.outbound.close.totals.outGPSRecorderQty += i.outGPSRecorderQty;
          this.outbound.close.totals.outPalletMovementQty +=
            i.outPalletMovementQty;
          this.outbound.close.totals.outSealQty += i.outSealQty;
          this.outbound.close.totals.outOvertimeQty += i.outOvertimeQty;
          this.outbound.close.totals.outPTIQty += i.outPTIQty;
        });

        this.outbound.close.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async download() {
      this.setLoading(true);
      try {
        const response = await outbound.calculationCloseExport(
          this.controls.searchForm
        );
        const blob = new Blob([response.body]);
        const link = document.createElement("a");
        link.className = "file";
        link.href = window.URL.createObjectURL(blob);
        link.download = "CloseOutbound.xlsm";
        document.body.appendChild(link);
        this.setLoading(false);
        link.click();
      } catch {
        this.setLoading(false);
      }
    },
    getAllCompanies(search) {
      companies.getCompanyCombo(search).then((res) => {
        this.controls.companies = res.body.data;
        this.controls.companies.unshift({
          idCompany: null,
          companyName: "ALL",
        });
      });
    },
    updateValues(values) {
      this.form.date.startDate = moment(values.startDate).format("YYYY-MM-DD");
      this.form.date.endDate = moment(values.endDate).format("YYYY-MM-DD");
    },
    checkOpen(open) {
      // console.log("event: open", open);
    },
    dateFormat(classes, date) {
      const dateToCompare = moment(date);
      let yesterday = moment().subtract(3, "day");
      //if (!classes.disabled) {
      //  classes.disabled = dateToCompare.isSame(yesterday, "day");
      //}
      return classes;
    },
  },
};
</script>

<style>
.reportrange-text {
  padding: 0px !important;
  border: none !important;
}
</style>
